import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Washington Experience" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-37">
        <div class="format_text">
          <h1>Washington Experience</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
                width: "300px",
                height: "199px",
              }}
            >
              <StaticImage
                src="../images/shutterstock_21801643-Washington-Experience.jpeg"
                alt="Washington DC skyline"
              />
            </span>
            For many companies it is very helpful to have someone on the board
            with Washington experience and an understanding of how to navigate
            successfully through the federal machinery.&nbsp;The two basic
            categories of governmental experience are (1) <em>regulatory</em>{" "}
            (Federal Communications Commission, Food and Drug Administration,
            Securities and Exchange Commission or Department of Energy, for
            example); and (2) <em>legislative experience</em>.
          </p>
          <p>
            Interested in a diplomatic career in his teens, Larry traveled to
            Washington, DC to study economics and international relations. While
            attending college, he worked mostly part-time on the staff of U.S.
            Senator Lawton Chiles of Florida for three years. Following
            graduation from business school, he worked for approximately three
            years in various capacities, including as Chief Administrative
            Officer, at the Mortgage Bankers Association of America. During this
            time he gained insight and experience as to administrative law and
            governmental relations.&nbsp;He has been asked and has provided
            testimony (economic analysis and policy strategy as an investment
            banker) before hearings of the United States Congress.&nbsp;For many
            years he has been a member of the{" "}
            <a href="http://www.unausa.org/">United Nations Association</a> and
            the{" "}
            <a href="http://www.dfwworld.org/">
              World Affairs Council of Dallas/Fort Worth
            </a>
            .
          </p>
          <p>
            While in Washington early in his career, having completed all course
            requirements necessary for the CPA exam, he worked at Disclosure,
            Inc. as a financial analyst under a contract with the U.S.<em> </em>
            Securities and Exchange Commission, conducting financial analysis
            and abstracting and indexing certain financial statements and other
            disclosure items (Forms 10-K, 8-K, 10-Q, etc.) filed with the
            Securities and Exchange Commission.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
